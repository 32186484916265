export const loadFonts = () => {
  const kievitLightItalic = new FontFace(
    'KievitOT',
    'url(/assets/fonts/KievitOT-LightItalic.otf)',
    {
      style: 'italic',
      weight: '300',
    }
  )

  const kievit = new FontFace(
    'KievitOT',
    'url(/assets/fonts/KievitOT-Regular.otf)',
    {
      style: 'normal',
      weight: '400',
    }
  )

  const kievitBold = new FontFace(
    'KievitOT',
    'url(/assets/fonts/KievitOT-Bold.otf)',
    {
      style: 'normal',
      weight: '700',
    }
  )

  const kievitBlack = new FontFace(
    'KievitOT',
    'url(/assets/fonts/KievitOT-Black.otf)',
    {
      style: 'normal',
      weight: '900',
    }
  )
  const circularMedium = new FontFace(
    'CirculatStd',
    'url(/assets/fonts/CircularStd-Medium.otf)',
    {
      style: 'normal',
      weight: '500',
    }
  )
  const circularBold = new FontFace(
    'CirculatStd',
    'url(/assets/fonts/CircularStd-Bold.otf)',
    {
      style: 'normal',
      weight: '700',
    }
  )

  // This only generates a small glitch (portfolio, set item width)
  // solved by adding <link rel="preload"…
  document.fonts.add(kievitLightItalic)
  document.fonts.add(kievit)
  document.fonts.add(kievitBold)
  document.fonts.add(kievitBlack)
  document.fonts.add(circularMedium)
  document.fonts.add(circularBold)

  return document.fonts.ready
}
