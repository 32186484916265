























































/* eslint-disable no-mixed-operators */
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'

import ChromeHeaderPrimary from '@/components/chrome/header/Primary.vue'
import ChromeHeaderSecondary from '@/components/chrome/header/Secondary.vue'
import ChromeSocials from '@/components/chrome/Socials.vue'
import ChromeHeaderLegal from '@/components/chrome/header/Legal.vue'
import ChromeHeaderCta from '@/components/chrome/header/Cta.vue'

import gsap, { TimelineLite } from 'gsap'

export default defineComponent({
  name: 'ChromeHeaderMenu',
  components: {
    ChromeHeaderPrimary,
    ChromeHeaderSecondary,
    ChromeSocials,
    ChromeHeaderLegal,
    ChromeHeaderCta,
  },
  setup() {
    const { isMenuOpen } = useGetters('ui', ['isMenuOpen'])
    const rootElRef = ref<HTMLElement | null>(null)
    const isMobileRef = ref(false)
    const breakpoint = 1024

    let openTl: TimelineLite
    let closeTl: TimelineLite

    const { SET_TRANSITION_TYPE: setTransitionType, CLOSE_MENU: closeMenu } =
      useMutations('ui', ['SET_TRANSITION_TYPE', 'CLOSE_MENU'])

    // Create opening animation
    const initOpenTl = () => {
      if (!rootElRef.value) {
        return
      }

      const primaryEls = rootElRef.value.querySelectorAll(
        '.header-primary__item'
      )
      const secondaryEls = rootElRef.value.querySelectorAll(
        '.header-secondary__item'
      )
      const socialsEls = rootElRef.value.querySelectorAll('.socials__item')
      const legalsEls = rootElRef.value.querySelectorAll('.header-legal__item')
      const ctasEls = rootElRef.value.querySelectorAll('.header-menu__cta')

      openTl = gsap.timeline({
        paused: true,
        delay: 0.5,
      })

      openTl
        .fromTo(rootElRef.value, { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .add('show-elements', 0.3)
        .fromTo(
          primaryEls,
          {
            x: -10,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            stagger: 0.05,
            duration: 0.5,
          },
          'show-elements'
        )
        .fromTo(
          ctasEls,
          {
            xPercent: isMobileRef.value ? 0 : 20, // on desktop : slide left
            y: isMobileRef.value ? 50 : 0, // on mobile : slide up
            opacity: 0,
          },
          {
            xPercent: 0,
            y: 0,
            opacity: 1,
            stagger: 0.25,
            duration: 1,
            ease: 'power4.out',
          },
          'show-elements+=0.1'
        )
        .fromTo(
          secondaryEls,
          {
            x: -10,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            stagger: 0.05,
            duration: 1,
            ease: 'expo.out',
          },
          'show-elements+=0.2'
        )
        .fromTo(
          socialsEls,
          {
            x: isMobileRef.value ? 0 : 50, // on desktop : slide left
            y: isMobileRef.value ? 50 : 0, // on mobile : slide up
            opacity: 0,
          },
          {
            x: 0,
            y: 0,
            opacity: 1,
            stagger: 0.05,
            duration: 1,
            ease: 'power4.out',
          },
          'show-elements+=0.4'
        )
        .fromTo(
          legalsEls,
          {
            x: isMobileRef.value ? 0 : 50, // on desktop : slide left
            y: isMobileRef.value ? 50 : 0, // on mobile : slide up
            opacity: 0,
          },
          {
            x: 0,
            y: 0,
            opacity: 1,
            stagger: 0.05,
            duration: 1,
            ease: 'power4.out',
          },
          'show-elements+=0.5'
        )
        .set([primaryEls, secondaryEls, socialsEls, legalsEls, ctasEls], {
          clearProps: 'x,y,xPercent,yPercent,opacity',
        })
    }

    // Create closing animation
    const initCloseTl = () => {
      if (!rootElRef.value) {
        return
      }

      const menusEls = rootElRef.value.querySelectorAll(
        '.header-primary, .header-secondary, .header-menu__socials, .header-menu__legal'
      )
      const ctasEls = rootElRef.value.querySelectorAll('.header-menu__cta')

      closeTl = gsap.timeline({
        paused: true,
      })

      closeTl
        .to(
          ctasEls,
          {
            xPercent: isMobileRef.value ? 0 : 20, // on desktop : slide right
            y: isMobileRef.value ? 50 : 0, // on mobile : slide down
            opacity: 0,
            stagger: -0.05,
            duration: 0.5,
            ease: 'power4.in',
          },
          '0'
        )
        .to(menusEls, { opacity: 0 }, '<+=0.1')
        .fromTo(rootElRef.value, { opacity: 1 }, { opacity: 0, duration: 0.2 })
        .set([menusEls, ctasEls], { clearProps: 'xPercent,y,opacity' })
    }

    // Promise called by Header.vue
    const open = () =>
      new Promise<void>(resolve => {
        // Reset transitiontype
        setTransitionType('default')

        // Cancel close animation if it is running
        if (closeTl && closeTl.isActive()) {
          closeTl.kill()
        }

        openTl
          .invalidate()
          .restart()
          .eventCallback('onComplete', () => resolve())
      })

    // Promise called by Header.vue
    const close = () =>
      new Promise<void>(resolve => {
        // Cancel open animation if it is running
        if (openTl && openTl.isActive()) {
          openTl.kill()
        }

        closeTl
          .invalidate()
          .restart()
          .eventCallback('onComplete', () => resolve())
      })

    const onResize = (x: number) => {
      if (isMobileRef.value !== x < breakpoint) {
        isMobileRef.value = x < breakpoint

        if (!isMenuOpen.value) {
          initOpenTl()
          initCloseTl()
        }
      }

      isMobileRef.value = x < breakpoint
    }

    // Close menu when clicking on active link
    const onClickActive = () => {
      console.log('Close menu because active')

      // close menu by mutating isMenuOpen
      if (isMenuOpen.value) {
        closeMenu()
      }
    }

    onMounted(() => {
      isMobileRef.value = window.innerWidth < breakpoint
      initOpenTl()
      initCloseTl()
    })

    return {
      ...useGetters(['chrome', 'currentLang', 'languages']),
      rootElRef,
      open,
      close,
      onResize,
      onClickActive,
    }
  },
})
