









import { defineComponent, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'CustomScript',
  components: {},
  props: {
    src: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const dynamicSrc = ref<string | null>(null)

    onMounted(() => {
      dynamicSrc.value = props.src
    })

    return {
      dynamicSrc,
    }
  },
})
