/* eslint-disable @typescript-eslint/no-explicit-any */
import { VNode, VNodeData, VNodeDirective } from 'vue'
import { getThumborUrl } from '@/inc/app.config'

// Directives
export interface VueSrcData {
  src: string
  rawUrl?: string
  sets?: Record<string, string>
}
export type VueSrcSets = string[]
export type VueSrcTransform = string | string[]

interface VueSrcAttrs {
  src: string
  srcset?: string
}
interface Data {
  sets: VueSrcSets
  transform: VueSrcTransform
  src: string
  file: string
}

export const defaultSets: string[] = [
  '320',
  '480',
  '640',
  '960',
  '1280',
  '1440',
  '1600',
  '1920',
  '2240',
  '2560',
  '2880',
]

/*
 * Construct image effects
 * @param transform - Thumbor transformations (https://thumbor.readthedocs.io/en/latest/usage.html)
 * @param size - Image breakpoint
 */
// eslint-disable-next-line
const buildTransforms = (
  transform: string | string[],
  size: string
): string => {
  const transformations = Array.isArray(transform) ? transform : [transform]

  return transformations.reduce(
    (acc, item) =>
      item === 'fit-in' ? `${acc}${item}/${size}x/` : `${acc}${item}/`,
    ''
  )
}

/*
 * Construct picture url with size
 * @param file - File name with path without root domain
 * @param transform - Thumbor transformations
 */
const buildUrl = (
  baseUrl: string,
  file: string,
  size: string,
  transform: string | string[]
): string => `${baseUrl}/${buildTransforms(transform, size)}--/${file} ${size}w`

/*
 * Construct srcset attribute value
 * @param sets - Breakpoint sizes
 * @param file - File name with path without root domain
 * @param transform - Thumbor transformations
 */
export const buildSet = (
  baseUrl: string,
  sets: string[],
  file: string,
  transform: string | string[]
): string =>
  sets.map(size => buildUrl(baseUrl, file, size, transform)).join(', ')

const init = (
  values: Record<string, any>,
  attrs: Record<string, any> = {}
): Data => {
  const { src, rawUrl: file } = values
  const { sets = defaultSets, transform = 'fit-in' } = attrs

  return {
    sets: Array.isArray(sets) ? sets : sets.split(','),
    transform,
    src,
    file,
  }
}

const createLegacyAttrs = (
  values: Record<string, any>,
  attrs: Record<string, any>
): VueSrcAttrs => {
  const { sets, transform, src, file } = init(values, attrs)
  let srcset: string | undefined

  if (file) {
    srcset = buildSet(getThumborUrl(), sets, file, transform)
  }

  return { src, srcset }
}

const createSignedAttrs = (
  values: Record<string, any>,
  attrs: Record<string, any>
): VueSrcAttrs => {
  const { sets: pictureSets, src } = values
  let sets = attrs && attrs.sets ? attrs.sets : defaultSets
  sets = Array.isArray(sets) ? sets : sets.split(',')

  const srcset = sets
    .filter((size: string) => pictureSets[size])
    .map((size: string) => `${pictureSets[size]} ${size}w`)
    .join(', ')

  return { src, srcset }
}

export default {
  ssr(vnode: VNode, directiveMeta: VNodeDirective) {
    const { data } = vnode as { data: VNodeData }
    const { attrs = {} } = data as { attrs: Record<string, any> }

    const { src, srcset } = directiveMeta.value.sets
      ? createSignedAttrs(directiveMeta.value, attrs)
      : createLegacyAttrs(directiveMeta.value, attrs)

    attrs.src = src

    if (srcset) {
      attrs.srcset = srcset
    }

    data.attrs = attrs

    if (!attrs.alt) {
      data.attrs.alt = directiveMeta.value.alt
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind(el: HTMLElement, binding: any, vnode: VNode) {
    if (el.hasAttribute('src')) {
      return
    }

    const { data } = vnode as { data: VNodeData }
    const { attrs } = data as { attrs: Record<string, any> }

    const { src, srcset } = binding.value.sets
      ? createSignedAttrs(binding.value, attrs)
      : createLegacyAttrs(binding.value, attrs)

    el.setAttribute('src', src)

    if (srcset) {
      el.setAttribute('srcset', srcset)
    }
  },
}
