














































import type { Route } from 'vue-router'

import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Vlitejs from 'vlitejs'
import VlitejsYoutube from 'vlitejs/dist/providers/youtube'
import { debounce } from 'throttle-debounce'

import DevGrid from '@/components/DevGrid.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import PageTransition from '@/components/PageTransition.vue'

import NotFound from '@/views/NotFound.vue'

import { push, setVHCSSVariable, campaignManager } from '@/inc/utils'
import { StorageUtils } from '@/inc/plugins/storage'
import { isUnderMaintenance, getApiUrl, gtm } from '@/inc/app.config'
import favicon from '@/inc/custom/favicon'
import { loadFonts } from '@/inc/custom/fonts'

const RESIZE_DEBOUNCE = 150

export default Vue.extend({
  name: 'app',
  components: {
    ChromeHeader,
    DevGrid,
    EnvSwitcher,
    NotFound,
    ChromeFooter,
    PageTransition,
  },
  data() {
    return {
      hasError: false,
      isUnderMaintenance,
      body: {} as HTMLElement,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template', 'content']),
    ...mapGetters('ui', ['videoId']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
      updateCountry: 'UPDATE_COUNTRY',
    }),
    ...mapActions(['fetchChrome']),
    onResize() {
      setVHCSSVariable()
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    this.$logger.trace('[app:created]', this.$$type)

    if (!this.$isServer) {
      this.hasError =
        document.querySelector('[data-server-rendered].error') !== null
    }

    if (this.hasError) {
      return
    }

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }

    if (this.$isServer) {
      this.updateCountry(this.$root.$options.$country)
    } else {
      this.updateCountry(document.documentElement.dataset.country)
    }
  },
  beforeMount() {
    // Provider needs to be registered only once
    Vlitejs.registerProvider('youtube', VlitejsYoutube)

    loadFonts()
  },
  mounted() {
    this.$logger.trace('[app:mounted]')
    this.body = document.body
    this.$on('scroll-disabled', () => (this.body.style.overflow = 'hidden'))
    this.$on('scroll-enabled', () => this.body.removeAttribute('style'))

    // Between pages...
    this.$router.beforeEach((_to: Route, _from: Route, next: () => void) => {
      ;(document.activeElement! as HTMLElement).blur()
      next()
    })

    this.$root.$on('page:ready', () => {
      // Init campaign manager
      campaignManager.init(this.$root.$cookie, this.$router)
    })

    this.$root.$emit('page:ready')

    this.onResize = debounce(RESIZE_DEBOUNCE, this.onResize)
    window.addEventListener('resize', this.onResize)
    setVHCSSVariable()
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)

      // Activate Optimize
      push({ event: 'optimize.activate' })
    },
  },
  head() {
    const link = favicon?.link.concat(this.meta?.link || [])

    // Preconnect API
    link.push({
      rel: 'preconnect',
      href: getApiUrl(),
    })

    // Preconnect GTM
    link.push({
      rel: 'preconnect',
      href: `https://www.googletagmanager.com/gtm.js?id=${
        process.env.EPIC_ENV === 'production' ? gtm.production : gtm.staging
      }`,
    })

    // Preload Hero picture
    const heroPicture =
      this.content.header?.picture ||
      this.content.hero?.picture ||
      this.content.picture ||
      this.content.flexibleContent?.[0].picture

    if (heroPicture) {
      link.push({
        rel: 'preload',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        as: 'image',
        href: heroPicture.src,
        imagesrcset: Object.keys(heroPicture.sets).map(
          (size: string) => `${heroPicture.sets[size]} ${size}w`
        ),
        imagesizes: '100vw',
        crossorigin: true,
        fetchpriority: 'high',
      })
    }

    return {
      ...this.meta,
      link,
      meta: favicon?.meta.concat(this.meta?.meta || []),
    }
  },
})
